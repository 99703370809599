form {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
    padding: 30px 0px;
}

label,
form h4 {
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 1.75rem;
    padding-top: 20px;
}

input,
textarea {
    height: 40px;
    padding: 10px;
    font-size: 1.5rem;
    border: 1px solid white;
    font-family: 'Alegreya Sans', sans-serif;
    border-bottom: 1px solid var(--tupe);
}

.radio-buttons-container {
    display: flex;
}

.radio-button {
    display: flex;
    align-items: center;
    margin: 0px 30px;
}

.radio-button label {
    padding-top: 0%;
    margin-left: 10px;
}

textarea {
    font-family: 'Alegreya Sans', sans-serif;
    padding: 10px;
    font-size: 1.5rem;
    resize: none;
}

input:hover,
textarea:hover {
    border: 2px solid var(--light);
}

input:focus,
textarea:focus {
    outline-color: var(--light);
}

input[type="radio"]:checked {
    accent-color: var(--tupe);
}


.form-button {
    border-radius: 15px;
    width: 200px;
    font-size: 1.50rem;
    background-color: var(--tupe);
    border: 1px solid var(--light);
    margin-top: 30px;
    font-family: 'Alegreya Sans', sans-serif;
    margin-left: auto;
    color: var(--light);
}

.form-button:hover {
    opacity: .5;
    color: var(--dark);
}

.thanks {
    text-align: center;
    font-size: 3rem;
    color: var(--tan2);
    font-family: 'Playfair Display', serif;
    margin-bottom: 0px;
    padding: 50px 0px;
}

@media screen and (max-width: 765px) {
    form {
        width: 80%;
        margin: auto;
        padding: 50px 0px;
    }
}