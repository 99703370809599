.main-navbar {
    background-color: var(--dark);
    display: flex;
}

.logo-item {
    padding-top: 20px;
}

.main-nav-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.main-nav-links a,
.nav-links-mobile a {
    color: var(--light);
    text-decoration: none;
    font-size: 1.5rem;
    font-family: 'Alegreya Sans', sans-serif;
}

.main-nav-links a:hover {
    color: var(--tupe);
}

.mobile-menu-icon {
    display: none;
}

.nav-links-mobile {
    display: none;
}

@media screen and (max-width: 900px) {
    .main-navbar {
        flex-direction: column;
    }

    .logo-item {
        margin: auto;
    }
}

@media screen and (max-width: 600px) {

    .logo-item img {
        margin-left: 2%;
        width: 70%;
    }

    .main-nav-links {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
        position: absolute;
        top: 25px;
        right: 25px;
        font-size: 32px;
        color: var(--light);
        background-color: var(--dark);
        border: none;
        outline: none;
    }

    .nav-links-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style: none;
        background-color: var(--dark);
        transition: all 0.5s ease-out;
        width: 100%;
    }

    .nav-links-mobile a:hover {
        color: var(--tupe);
    }

}

@media screen and (max-width: 415px) {
    .mobile-menu-icon {
        top: 1%;
        font-size: 25px;
    }
}