.about-cover {
    position: relative;
}

.about-page-image {
    display: flex;
    width: 100%;
}

.about-page-image img {
    width: 100%;
}

.about-title {
    /* background-color: hsla(0, 0%, 5%, 0.38); */
    color: var(--light);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Playfair Display', serif;
}

.about-title h2 {
    font-size: 4rem;
}

/* How I Can Help Section */
.about-section-3 h2 {
    background-color: var(--dark);
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    text-align: center;
    padding-top: 50px;
    color: var(--light);
    margin-bottom: 0px;
}

.about-section-3-container {
    background-color: var(--dark);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 50px 0px;
}

.about-section-3-image {
    display: flex;
    height: 650px;
}

.about-section-3-image img {
    height: 100%;
    outline: 3px solid var(--dark);
    outline-offset: -10px;
}

.about-section-3-content {
    width: 45%;
}

.about-section-3-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    text-align: center;
    padding: 20px 0px;
    color: var(--light);
}

.about-section-3-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--light)
}

.about-section-3-cards img {
    width: 30%;
}

.about-section-3-cards h3 {
    font-family: 'Playfair Display', serif;
}



/* Experience Section  */

.experience {
    background-color: var(--light);
    color: var(--tupe);
}

.experience h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    text-align: center;
    padding: 20px 0px;

}

.experience-container {
    display: flex;
    justify-content: space-evenly;
}

.experience-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
    width: 30%;
}

.experience-content img {
    width: 50px;
    margin: auto;
}

.experience-content h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
}

.experience-content p {
    font-family: 'Alegreya Sans', sans-serif;
    font-weight: 600;
    font-size: 1.75rem;
}

.qualifications-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 50px;
}

.q-cards {
    width: 40%;
    background-color: white;
    outline: 3px solid var(--light);
    outline-offset: -10px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.license-card h4 {
    font-size: 2rem;
}

.q-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.q-card-content img {
    width: 50px;
    margin: 15px;
}

.q-card-content h4 {
    font-family: 'Playfair Display', serif;
}

.q-card-content p {
    font-family: 'Alegreya Sans', sans-serif;
    font-weight: 600px;
    font-size: 1.25rem;
}

/* About - Send Inquiry */
.about-contact-content {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 0px;
    background-color: var(--light);
}

.about-contact-content img {
    width: 25%;
}

.about-contact-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    padding: 20px 20px;
    color: var(--tan2);
}

.about-contact-content a {
    color: var(--tan2);
    font-size: 3.5rem;
    font-family: 'Alegreya Sans', sans-serif;
    text-decoration: none;
    flex-wrap: wrap;
}

.about-contact-content a:hover {
    color: var(--dark);
    border-bottom: 1px solid var(--dark);
}



@media screen and (max-width: 900px) {
    .about-section-3-image {
        height: 500px;
    }
}



@media screen and (max-width: 765px) {
    .about-section-3-container {
        flex-direction: column;
    }

    .about-section-3-image {
        height: 650px;
    }

    .about-section-3-content {
        width: 95%;
    }

    .experience-container {
        flex-direction: column;
        align-items: center;
    }

    .experience-content {
        width: 90%;
    }

    .qualifications-container {
        flex-direction: column;
        align-items: center;
    }

    .q-cards {
        width: 90%;
        margin-bottom: 30px;
    }

    .q-card-content img {
        width: 40px;
    }

    .about-contact-content img {
        width: 45%;
    }

}

@media screen and (max-width: 550px) {
    .about-section-3-image {
        height: 400px;
    }

    .q-card-content img {
        width: 35px;
    }

    .license-card img {
        margin-left: 7%;
    }

    .about-contact-content h2 {
        font-size: 2rem;
    }

    .about-contact-content a {
        font-size: 2.5rem;
    }

}