/* Couples Cover Banner */
.couples-banner-container {
    width: 100%;
    position: relative;
}

.couples-banner-image {
    width: 100%;
    display: flex;
}

.couples-banner-image img {
    width: 100%;
}

.couple-banner-quote {
    color: var(--light);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.couple-banner-quote h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3.5rem;
    color: var(--light);
}

/* About Couples Therapy */

.couples-intro {
    background-color: var(--dark);
    color: var(--light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 0px;
}

.couples-intro img {
    width: 30%;
}

.couples-intro h2 {
    font-family: 'Playfair Display', serif;
}

.couples-intro p {
    width: 75%;
    font-family: 'Alegreya Sans', sans-serif;
    font-weight: 800;
    font-size: 1.75rem;
}

/* Benefits of Couples Therapy */
.couples-intro-two {
    padding: 50px 0px;
    background-color: var(--light);
    text-align: center;
}

.couples-intro-two h2 {
    font-size: 2.75rem;
    padding-bottom: 20px;
    font-family: 'Playfair Display', serif;
}

.couples-intro-two p {
    font-family: 'Alegreya Sans', sans-serif;
    font-weight: 800;
    font-size: 1.5rem;
    width: 70%;
    margin: auto;
    padding-bottom: 50px;
}

.couples-intro-two-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.couples-intro-two-image {
    height: 510px;
    display: flex;
    margin-left: 10%;
}

.couples-intro-two-image img {
    height: 100%;
    outline: 10px double black;
    outline-offset: -10px;
}

/* .couples-intro-two-text {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.couples-list-container {
    width: 50%;
    list-style-type: none;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
}

.couples-list-container li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.couples-list-container li p {
    margin-bottom: 0px;
    font-size: 1.75rem;
    margin: 0px;
    padding-bottom: 0px;
    text-align: left;
}


.couples-list-container img {
    width: 35px;
    margin-right: 15px;
}

/* What to Expect */
.process {
    background-color: var(--light);
}

.process h2 {
    font-size: 2.75rem;
    font-family: 'Playfair Display', serif;
    text-align: center;
    color: var(--dark);
    padding: 0px 20px;
}

.process-container {
    display: flex;
    justify-content: space-evenly;
    padding: 40px 10px;
}

.process-cards {
    width: 30%;
    background-color: white;
    padding: 20px;
    outline: 2px solid var(--light);
    outline-offset: -10px;
    color: var(--dark);
}

.process-cards img {
    width: 40px;
    display: block;
    margin: auto;
}


.process-cards h3 {
    font-size: 2rem;
    font-family: 'Playfair Display', serif;
    text-align: center;
    padding: 10px;
}

.process-cards p {
    font-size: 1.5rem;
    font-family: 'Alegreya Sans', sans-serif;
    padding: 10px;
}

/* Couples - Send Inquiry */
.couples-contact-section {
    display: flex;
    justify-content: space-evenly;
    background-color: var(--dark);
    padding: 40px 10px;
}

.couples-contact-content {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.couples-contact-content h2 {
    font-size: 2rem;
    padding-bottom: 20px;
    font-family: 'Playfair Display', serif;
    color: var(--light);
    text-align: center;
}

.couples-contact-content img {
    width: 80%;
    display: block;
    margin: 0px auto;
}

.couples-contact-content a {
    font-size: 2.5rem;
    padding-bottom: 20px;
    font-family: 'Alegreya Sans', sans-serif;
    color: var(--light);
    text-align: center;
    text-decoration: none;
}

.couples-contact-content a:hover {
    color: var(--tupe);
    border-bottom: 1px solid var(--tupe);
}


.couple-b-container {
    height: 600px;
    display: flex;
    justify-content: center;
}

.couple-b-container img {
    height: 100%;
    outline: 10px double black;
    outline-offset: -10px;
}



@media screen and (max-width:1050px) {
    .couples-intro-two-image {
        height: 400px;
    }

    .couples-list-container {
        width: 60%;
    }

    .couples-list-container li p {
        font-size: 1.25rem;
    }

    .process-container {
        flex-direction: column;
        justify-content: center;
    }

    .process-cards {
        width: 80%;
        margin: 20px auto;
        text-align: center;
    }
}

@media screen and (max-width:760px) {
    .couples-intro-two-container {
        flex-direction: column;
    }

    .couples-intro-two-image {
        height: 560px;
        margin-left: 0px;
    }

    .couples-list-container {
        width: 90%;
        padding-top: 40px;
    }

    .couples-list-container li p {
        font-size: 1.75rem;
    }

    .couples-contact-section {
        flex-direction: column-reverse;
    }

    .couple-b-container {
        height: 500px;
    }

    .couples-contact-content {
        width: 80%;
        margin: 40px auto;
    }

}

@media screen and (max-width:550px) {
    .couple-banner-quote h1 {
        font-size: 1.5rem;
    }

    .couples-intro h2 {
        font-size: 2.5rem;
        padding: 0px 10px;
    }

    .couples-intro p {
        width: 90%;
        font-size: 1.5rem;
    }

    .couples-intro-two-image {
        height: 350px;
        margin-left: 0px;
    }

    .couples-intro-two h2 {
        font-size: 2rem;
        padding: 0px 10px;
    }

    .couples-intro-two p {
        width: 95%;
    }

    .process h2 {
        font-size: 2rem;
    }

    .process-cards {
        width: 90%;
    }

    .couple-b-container {
        height: 400px;
    }
}