.family-banner {
    display: flex;
    background-color: var(--tupe);
    /* padding-bottom: 20px; */
}

.family-banner img {
    height: 470px;
}

.family-banner-content {
    width: 50%;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.family-banner-content h1 {
    color: white;
    font-size: 2.75rem;
    padding-bottom: 10px;
    font-family: 'Playfair Display', serif;
}

.family-banner-content p {
    font-family: 'Alegreya Sans', sans-serif;
    color: white;
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 0px;
}

/* About Family Therapy */
.family-therapy-about {
    display: flex;
    align-items: center;
    background-color: var(--light);
    padding: 40px 0px;
}

.family-therapy-about-content {
    width: 50%;
    text-align: center;
    padding: 0px 20px;
}

.family-therapy-about-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    color: var(--tan2);
}

.family-therapy-about-content p {
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 0px;
}

.family-therapy-about img {
    width: 50%;
}

/* Family Therapy can help Section */
.family-issues-section {
    background-color: var(--light);
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 50px;
}

.family-issue-image {
    height: 650px;
    display: flex;
}

.family-issue-image img {
    height: 100%;
    outline: 10px double var(--tupe);
    outline-offset: -10px;
}

.family-issues-container {
    width: 50%;
}

.family-issues-container h2 {
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    padding: 10px;
    color: var(--tupe);
}

.family-issue-list {
    padding-left: 0px;
    list-style-type: none;
}

.family-issue-list li {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.family-issue-list li img {
    width: 35px;
    margin-right: 15px;
}

.family-issue-list li p {
    margin-bottom: 0px;
    font-size: 1.75rem;
    margin: 0px;
    padding-bottom: 0px;
    text-align: left;
    font-family: 'Alegreya Sans', sans-serif;
}

/* Family Contact Section */
.family-contact-section {
    display: flex;
    justify-content: space-evenly;
    background-color: var(--light);
    padding: 40px 0px;
}

.family-contact-content {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.family-contact-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    text-align: center;
    color: var(--tan2);
}

.family-contact-content img {
    width: 70%;
    display: flex;
    margin: 0px auto;
}

.family-contact-content a {
    font-size: 2.5rem;
    font-family: 'Alegreya Sans', sans-serif;
    color: var(--tan2);
    text-align: center;
    text-decoration: none;
}

.family-contact-content a:hover {
    color: var(--dark);
    border-bottom: 1px solid var(--dark);
}

.family-c-container {
    height: 610px;
    display: flex;
}

.family-c-container img {
    height: 100%;
    outline: 10px double white;
    outline-offset: -10px;
}


@media screen and (max-width: 1200px) {
    .family-banner img {
        height: 350px;
    }

    .family-issue-image {
        height: 500px;
    }

    .family-issues-container {
        width: 50%;
        margin-left: 20px;
    }

    .family-issues-container h2 {
        font-size: 2rem;
    }

    .family-issue-list li {
        padding-bottom: 15px;
    }

    .family-issue-list li p {
        font-size: 1.25rem;
    }
}


@media screen and (max-width: 960px) {
    .family-banner img {
        height: 300px;
    }

    .family-banner-content h1 {
        font-size: 2rem;
        padding-bottom: 0px;
    }

    .family-banner-content p {
        font-size: 1.25rem;
    }

    .family-therapy-about {
        flex-direction: column-reverse;
    }

    .family-therapy-about-content {
        width: 90%;
    }

    .family-c-container {
        height: 450px;
    }
}

@media screen and (max-width: 765px) {
    .family-banner {
        flex-direction: column;
    }

    .family-banner img {
        height: 100%;
    }

    .family-banner-content {
        width: 90%;
        padding-bottom: 20px;
    }

    .family-banner-content h1 {
        font-size: 2.5rem;
        padding: 20px 10px;
    }

    .family-banner-content p {
        font-size: 1.5rem;
    }

    .family-issues-section {
        flex-direction: column;
        align-items: center;
    }

    .family-issue-image {
        width: 70%;
        height: auto;
        margin: auto;
    }

    .family-issue-image img {
        width: 100%;
        margin: auto;
    }

    .family-issues-container {
        width: 90%;
    }

    .family-issue-list li p {
        font-size: 1.5rem;
    }

    .family-contact-section {
        flex-direction: column-reverse;
    }

    .family-c-container {
        height: 550px;
        margin: auto;
    }

    .family-contact-content {
        width: 80%;
        margin: 40px auto;
    }
}

@media screen and (max-width: 550px) {
    .family-c-container {
        height: 350px;
    }
}