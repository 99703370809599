/* Cover Page Section */
.home-cover {
    position: relative;
}

.cover-page-image {
    display: flex;
    width: 100%;
}

.cover-page-image img {
    width: 100%;
}

.cover-quote {
    background-color: hsla(0, 0%, 5%, 0.38);
    color: var(--light);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.cover-quote h2 {
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 3.5rem;
}

.cover-quote p {
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 2rem;
}

/* Welcome Section */
.welcome {
    background-color: var(--light);
    padding: 10px;
}

.welcome-container {
    width: 75%;
    margin: auto;
    padding: 20px;
    color: var(--tupe);
    text-align: center;
}

.welcome-container h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
}

.welcome-container p {
    font-family: 'Alegreya Sans', sans-serif;
    font-weight: 800;
    font-size: 1.5rem;
}

.welcome-container img {
    width: 30%;
}

/* Expertise Section */

.expertise {
    width: 100%;
    background-color: var(--light);

}

.expertise h1 {
    text-align: center;
    font-size: 3rem;
    color: var(--tupe);
    font-family: 'Playfair Display', serif;
}

.expertise-cards-container {
    display: flex;
    justify-content: space-evenly;
    padding: 30px;
}

.expertise-cards {
    width: 30%;
    background-color: var(--tupe);
    color: var(--light);
    text-align: center;
    border-radius: 10px;
}

.expertise-cards img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.expertise-cards h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    padding-top: 20px;
}

.expertise-cards p {
    font-family: sans-serif;
    font-weight: 200;
    font-size: 1.25rem;
    padding: 15px;
}

.expertise-card-content a {
    font-family: 'Playfair Display', serif;
    color: var(--light);
    font-size: 1.25rem;
    margin-top: 10px;
    text-decoration: none;
    border-bottom: 1px solid var(--light);
    margin-bottom: 10px;
}

.expertise-card-content a:hover {
    color: var(--dark);
    border-bottom: 1px solid var(--dark);
    cursor: pointer;
}

/* About the Therapist */
.about-therapist {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--light);
    padding: 50px 0px;
}

.about-therapist-content {
    width: 50%;
    height: 90%;
    text-align: center;
}

.about-therapist-content h2 {
    color: var(--tupe);
    font-size: 2.75rem;
    padding-bottom: 50px;
    font-family: 'Playfair Display', serif;
}

.about-therapist-content h3 {
    font-size: 1.75rem;
    padding-bottom: 10px;
    font-family: 'Playfair Display', serif;
}

.about-therapist-content p {
    font-size: 1.5rem;
    padding-bottom: 10px;
    width: 70%;
    margin: auto;
    font-family: 'Alegreya Sans', sans-serif;
    font-weight: 600;
}

.about-therapist-content a {
    font-family: 'Playfair Display', serif;
    color: var(--dark);
    font-size: 1.25rem;
    text-decoration: none;
    border-bottom: 1px solid var(--dark);
}

.about-therapist-content a:hover {
    color: var(--tupe);
    border-bottom: 1px solid var(--tupe);
    cursor: pointer;
}

.about-therapist-image {
    display: flex;
    justify-content: center;
    height: 496px;
}

.about-therapist-image img {
    height: 100%;
    outline: 3px solid black;
    outline-offset: -10px;
}

/* Process Section */
.home-process {
    background-color: var(--light);
}

.home-process h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.75rem;
    text-align: center;
    color: var(--tan2);
    padding: 20px 0;
}

.home-process-container {
    display: flex;
    justify-content: space-evenly;
}

.process-card {
    width: 30%;
    text-align: center;
    color: var(--tan2);
    font-family: sans-serif;
    margin-bottom: 20px;
}

.process-card img {
    width: 75%;
}

.process-card h3 {
    font-size: 1.5rem;
    font-family: 'Playfair Display', serif;
    padding: 10px 0px;
}

.process-card p {
    font-size: 1.25rem;
    font-family: sans-serif;
    padding: 10px;
}

/* Testimonial section */

.testimonial-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light);
    padding: 50px 0px;
}

.testimonial-section h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2.75rem;
    text-align: center;
    color: var(--tupe);
    padding: 20px 0;
}

.ratings {
    display: flex;
    justify-content: center;
}

.ratings img {
    width: 30px;
}

/* Telehealth */
.telehealth-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--light);
    padding: 50px 0px;
}

.telehealth-image {
    display: flex;
    height: 467px;
}

.telehealth-image img {
    height: 100%;
    outline: 3px solid var(--tupe);
    outline-offset: -10px;
}

.telehealth-content {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.telehealth-content h2 {
    font-size: 2.5rem;
    font-family: 'Playfair Display', serif;
    color: var(--tupe);
    padding: 0px 0px 15px 0px;
}

.telehealth-content p {
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 1.5rem;
}

.telehealth-content a {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    color: var(--tupe);
    text-decoration: none;
}

.telehealth-content a:hover {
    color: black;
    border-bottom: 1px solid black;
}

@media screen and (max-width: 1100px) {
    .telehealth-image {
        height: 350px;
    }
}

@media screen and (max-width: 950px) {
    .welcome-container img {
        width: 60%;
    }

    .cover-quote h2 {
        font-size: 2rem;
    }

    .cover-quote p {
        font-size: 1rem;
    }

    .expertise-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .expertise-cards {
        width: 95%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
    }

    .expertise-cards img {
        width: 40%;
        border-radius: 10px 0px 0px 10px;
    }

    .expertise-card-content {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    .about-therapist-image {
        height: 380px;
    }

    .telehealth-image {
        height: 300px;
    }

    .telehealth-content h2 {
        font-size: 2rem;
    }

    .telehealth-content p {
        font-size: 1.25rem;
    }


}

@media screen and (max-width: 750px) {

    .expertise-cards {
        width: 85%;
        flex-direction: column;
    }

    .expertise-cards img {
        width: 100%;
        border-radius: 10px 10px 0px 0px;
    }

    .expertise-card-content {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    .about-therapist {
        flex-direction: column-reverse;
    }

    .about-therapist-content {
        width: 80%;
    }

    .about-therapist-image {
        height: 450px;
        margin-bottom: 30px;
    }

    .home-process-container {
        flex-direction: column;
        align-items: center;
    }

    .process-card {
        width: 70%;
    }

    .telehealth-section {
        flex-direction: column;
    }

    .telehealth-image {
        height: 450px;
        padding-bottom: 30px;
    }

    .telehealth-content {
        width: 70%;
    }
}

@media screen and (max-width: 560px) {
    .welcome-container {
        width: 98%;
    }

    .welcome-container h1 {
        font-size: 2.75rem;
    }

    .welcome-container img {
        width: 80%;
    }

    .cover-quote {
        position: static;
        background-color: hsla(0, 0%, 5%, 0.88);
    }

    .cover-quote p {
        font-size: 1.25rem;
        margin-bottom: 0px;
    }

    .expertise h1 {
        font-size: 2rem;
        padding: 0px 10px;
    }

    .expertise-cards {
        width: 100%;
    }

    .expertise-cards p {
        padding: 5px;
    }

    .about-therapist-image {
        height: 280px;
    }

    .about-therapist-content h2 {
        font-size: 2rem;
        padding-bottom: 30px;
    }

    .about-therapist-content p {
        width: 97%;
    }

    .home-process h2 {
        font-size: 2rem;
    }

    .process-card {
        width: 95%;
        text-align: center;
        color: var(--tan2);
        font-family: sans-serif;
        margin-bottom: 20px;
    }

    .telehealth-image {
        height: 280px;
    }
}