.footer-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--dark);
}

.footer-contact {
    width: 25%;
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 1.25rem;
    padding: 30px 0px 0px 0px;
}

.footer-contact h3 {
    font-size: 1.75rem;
    text-align: center;
}

.footer-contact ul {
    width: 100%;
    text-align: center;
    padding-left: 0px;
}

.footer-contact,
.footer-nav,
.footer-nav a {
    color: var(--light);
}

.footer-contact ul,
.footer-nav ul {
    list-style-type: none;
}

.email a {
    text-decoration: none;
    color: var(--light);
}

.email a:hover {
    color: var(--tupe);
}

.footer-location a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    ;
}

.footer-location a:hover {
    color: var(--tupe);
}

.footer-location a {
    color: var(--light);
    text-decoration: none;
}

.location-icon {
    fill: var(--light);
    width: 30px;
}

.location-icon:hover {
    fill: var(--tupe);
}

.footer-logo {
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-logo a img {
    width: 100%;
}

.socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--light);
}

.social-icons {
    fill: var(--light);
    width: 25px;
    margin-left: 10px;
}

.social-icons:hover {
    fill: var(--tupe);
}

.footer-nav {
    width: 25%;
}

.footer-nav h3 {
    text-align: center;
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 2rem;
    padding: 30px 0px 10px 0px;
}

.footer-nav-links {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1.5rem;
    font-family: 'Alegreya Sans', sans-serif;
    padding-left: 0%;
}

.footer-nav-links a {
    text-decoration: none;
    padding: 15px;
}

.footer-nav-links a:hover {
    color: var(--tupe);
}

.copywrite {
    text-align: center;
    padding: 20px;
    background-color: var(--dark);
    color: var(--light);
    margin-bottom: 0px;
}

@media screen and (max-width: 765px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-contact {
        flex-direction: column;
        width: 80%;
        margin: auto;
        text-align: center;
    }

    .footer-logo {
        width: 30%;
    }

    .socials {
        flex-direction: row;
    }

    .social-icons {
        margin-left: 10px;
    }

    .footer-nav {
        width: 80%;
        margin: auto;
        padding: 30px;
    }

}

@media screen and (max-width: 450px) {
    .footer-nav {
        width: 97%;
    }
}